<template>
  <header class="iv2-global">
    <div class="iv2-global__items">
      <h1
        class="iv2-global__logo"
        :class="{ active: scrollY > startScore || activeMenu }"
      >
        <div class="iv2-global__logo-block">
          <router-link
            v-if="information.sp_logo_image_header"
            :to="'/' + lang + query"
            class="iv2-global__link"
          >
            <picture class="d-none d-md-inline">
              <source
                type="image/webp"
                :srcset="
                  information.logo_image_header +
                  $store.state.publicLang.general.image_webp
                "
              />
              <img
                :src="information.logo_image_header"
                class="iv2-global__logo-img"
                :alt="organization.name"
              />
            </picture>
            <picture class="d-md-none">
              <source
                type="image/webp"
                :srcset="
                  information.sp_logo_image_header +
                  $store.state.publicLang.general.image_webp
                "
              />
              <img
                :src="information.sp_logo_image_header"
                class="iv2-global__logo-img"
                :alt="organization.name"
              />
            </picture>
          </router-link>
          <router-link v-else :to="'/' + lang + query" class="iv2-global__link">
            <picture>
              <source
                type="image/webp"
                :srcset="
                  information.logo_image_header +
                  $store.state.publicLang.general.image_webp
                "
              />
              <img
                :src="information.logo_image_header"
                class="iv2-global__logo-img"
                :alt="organization.name"
              />
            </picture>
          </router-link>
        </div>
      </h1>
      <div
        @click="touchHandler"
        class="iv2-global__menu theme__global-bg text-center"
        :class="{ inversion: scrollY < startScore }"
      >
        <div class="iv2-global__btn">
          <div
            :class="{ active: activeMenu }"
            class="iv2-global__btn-icon position-relative bg-transparent border border-0 p-0"
          >
            <span class="iv2-global__top-drawer theme__global-icon"></span>
            <span class="iv2-global__bottom-drawer theme__global-icon"></span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="iv2-global__heading-bar theme__global-bg"
      :class="{ active: scrollY > startScore || activeMenu }"
    ></div>
    <nav
      :class="{ active: activeMenu }"
      class="iv2-nav theme__nav-bg theme__nav-text position-fixed vh-100"
    >
      <div class="iv2-nav__block">
        <ul class="iv2-nav__list list-unstyled">
          <li
            v-for="nav in global"
            :key="nav.id"
            @click="closeMenu"
            class="iv2-nav__item theme__nav-item"
          >
            <router-link
              v-if="nav.link_type == 'internal'"
              :to="nav.link_href + lang + query"
              :target="nav.target_type"
              class="iv2-nav__link theme__nav-link"
              >{{ nav.item_name }}</router-link
            >
            <a
              v-else-if="nav.link_type == 'external'"
              :href="nav.link_href"
              :target="nav.target_type"
              rel="noopener"
              class="iv2-nav__link theme__nav-link"
              >{{ nav.item_name }}</a
            >
            <ul
              v-if="nav.children.length > 0"
              class="iv2-nav__list list-unstyled"
            >
              <li
                v-for="cNav in nav.children"
                :key="cNav.id"
                class="iv2-nav__item theme__nav-item--inner"
              >
                <router-link
                  v-if="cNav.link_type == 'internal'"
                  :to="cNav.link_href + lang + query"
                  :target="cNav.target_type"
                  class="iv2-nav__link theme__nav-link--inner"
                  >{{ cNav.item_name }}</router-link
                >
                <a
                  v-else-if="cNav.link_type == 'external'"
                  :href="cNav.link_href"
                  :target="cNav.target_type"
                  rel="noopener"
                  class="iv2-nav__link theme__nav-link--inner"
                  >{{ cNav.item_name }}</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="iv2-nav__footer">
        <div class="row">
          <div
            class="col-12 d-md-flex"
            style="flex-wrap: wrap; align-items: flex-end"
          >
            <div class="iv2-nav__footer-item col-md-4 pl-0 pr-5">
              <p class="size-18 mb-1">{{ organization.name }}</p>
              <p
                v-if="organization.free_text"
                v-html="organization.free_text"
                class="size-12 mx-0 mb-1"
                style="line-height: 1.5"
              ></p>
              <div style="line-height: 1.5">
                <span v-if="organization.zip_code" class="size-12 m-0">{{
                  "〒" + organization.zip_code
                }}</span>
                <span v-if="organization.prefecture_name" class="size-12 m-0">{{
                  organization.prefecture_name
                }}</span>
                <span v-if="organization.city_name" class="size-12 m-0">{{
                  organization.city_name
                }}</span>
                <span v-if="organization.town_name" class="size-12 m-0">{{
                  organization.town_name
                }}</span>
                <span v-if="organization.building_name" class="size-12 m-0">{{
                  organization.building_name
                }}</span>
              </div>
            </div>
            <div class="iv2-nav__footer-item">
              <p
                v-if="organization.tel_number"
                class="iv2-nav__footer-item--tel size-35 m-0 d-none d-md-block"
                style="line-height: 1.5"
              >
                {{ organization.tel_number }}
              </p>
              <p
                v-if="organization.opening_hours"
                v-html="organization.opening_hours"
                class="size-12 m-0"
                style="line-height: 1.5"
              ></p>
            </div>
          </div>
          <div class="col-12 text-right mt-2">
            <div class="iv2-nav__mobile-sns d-flex d-md-none m-auto">
              <span
                v-if="
                  !(
                    sns.is_linked_facebook == 0 &&
                    sns.is_linked_instagram == 0 &&
                    sns.is_linked_twitter == 0 &&
                    sns.is_linked_line == 0
                  )
                "
                class="mr-4 pt-2 size-12"
                >{{ $store.state.themeLang.general.sns }}</span
              >
              <ul class="iv2-nav__mobile-sns-list mb-0 list-unstyled">
                <li
                  v-if="sns.is_linked_facebook == 1"
                  class="iv2-nav__mobile-sns-item"
                >
                  <a
                    :href="sns.facebook_url"
                    class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                    target="_blank"
                    rel="noopener"
                  >
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li
                  v-if="sns.is_linked_instagram == 1"
                  class="iv2-nav__mobile-sns-item"
                >
                  <a
                    :href="sns.instagram_url"
                    class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                    target="_blank"
                    rel="noopener"
                  >
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
                <li
                  v-if="sns.is_linked_twitter == 1"
                  class="iv2-nav__mobile-sns-item"
                >
                  <a
                    :href="sns.twitter_url"
                    class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                    target="_blank"
                    rel="noopener"
                  >
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li
                  v-if="sns.is_linked_line == 1"
                  class="iv2-nav__mobile-sns-item"
                >
                  <a
                    :href="sns.line_url"
                    class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                    target="_blank"
                    rel="noopener"
                  >
                    <i class="fab fa-line"></i>
                  </a>
                </li>
                <li
                  v-if="rss.is_linked_blog == 1"
                  class="iv2-nav__mobile-sns-item"
                >
                  <a
                    :href="rss.url"
                    class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                    target="_blank"
                    rel="noopener"
                  >
                    <i class="fas fa-rss"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <!-- フリーページ項目に対応した動的height値指定 -->
    <div is="style">
      @media (min-width: 1200px) {
      {{ setHeight() }}
      }
    </div>
    <!-- フリーページ項目に対応した動的height値指定 -->
  </header>
</template>

<script>
export default {
  props: {
    global: Array,
    sns: Object,
    organization: Object,
    information: Object,
    rss: Object,
    count: Number,
  },

  data() {
    return {
      activeMenu: false,
      scrollY: 0,
      startScore: "",
      query: "",
      lang: "",
      environment: "",
    };
  },

  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.activeMenu = false;
        const el = document.body;
        el.classList.remove("noscroll");
      }

      if (this.$route.path == "/") {
        this.startScore = 100;
      } else {
        this.startScore = -1000;
      }
    },
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
    if (this.$route.path == "/") {
      this.startScore = 100;
    } else {
      this.startScore = "-1000";
    }
  },

  mounted() {
    if (this.$route.query.mode == "preview") {
      this.query = "?mode=preview";
    }
    if (this.$route.query.hl) {
      this.lang = "?hl=" + this.$route.query.hl;
    }
  },

  methods: {
    activeList(e) {
      e.currentTarget.parentNode.classList.toggle("active");
    },

    handleScroll() {
      this.scrollY = window.scrollY;
    },

    touchHandler() {
      this.activeMenu = !this.activeMenu;

      if (this.activeMenu == true) {
        const el = document.body;
        el.classList.add("noscroll");
      } else {
        const el = document.body;
        el.classList.remove("noscroll");
      }

      if (this.activeMenu == true && this.$route.path == "/") {
        this.startScore = -1000;
      } else if (this.activeMenu == false && this.$route.path == "/") {
        this.startScore = 100;
      }
    },

    closeMenu() {
      this.activeMenu = false;
      const el = document.body;
      el.classList.remove("noscroll");
    },

    /** フリーページにより大きく可変するナビゲーションに対応した高さを指定 */
    setHeight() {
      let navHeight = this.count * 50;
      if (this.count > 15) {
        return (
          ".iv2-global .iv2-nav .iv2-nav__block .iv2-nav__list {max-height: " +
          navHeight +
          "px;}"
        );
      }
    },
  },
};
</script>

<style lang="scss">
.iv2-global {
  .iv2-global__items {
    display: flex;

    .iv2-global__logo {
      width: 500px;
      height: 160px;
      margin: 20px;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 111;
      transition: 0.5s;

      .iv2-global__logo-block {
        position: relative;
        width: 100%;
        height: 100%;
      }

      &.active {
        width: 500px;
        height: 70px;
        margin: 0;
        padding: 5px;
      }

      .iv2-global__logo-img {
        transition: 0.4s;
        max-width: 90%;
        max-height: 90%;
        position: absolute;
        top: 50%;
        left: 10%;
        transform: translateY(-50%);
      }
    }

    .iv2-global__menu {
      z-index: 111;
      padding: 27px 20px;
      height: 70px;
      cursor: pointer;

      .iv2-global__btn {
        .iv2-global__btn-icon {
          width: 35px;
          height: 20px;

          .iv2-global__top-drawer,
          .iv2-global__bottom-drawer {
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            margin: auto;
            right: 0;
            left: 0;
            transition: 0.3s;
          }

          .iv2-global__top-drawer {
            top: 0px;
          }

          .iv2-global__bottom-drawer {
            bottom: 5px;
          }

          &.active {
            .iv2-global__top-drawer {
              top: 0;
              bottom: 0;
              transform: rotate(-45deg);
            }

            .iv2-global__bottom-drawer {
              top: 0;
              bottom: 0;
              transform: rotate(45deg);
            }
          }

          &:focus {
            outline: 0;
          }
        }
      }
    }
  }

  .iv2-global__heading-bar {
    width: 100%;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: none;
    animation: fadeIn 0.3s linear 0s;
    z-index: 11;

    &.active {
      display: block;
    }

    .iv2-global__logo-sp-img {
      height: 70px;
      vertical-align: top;
    }
  }

  .iv2-nav {
    top: 0;
    left: 0;
    padding: 150px 0 0 250px;
    width: 100vw;
    z-index: 1;
    visibility: hidden;
    display: none;
    overflow-y: auto;
    overflow-x: hidden;

    &.active {
      visibility: visible;
      display: block;
      animation: fadeIn 0.3s linear 0s;
    }

    .iv2-nav__block {
      > .iv2-nav__list {
        margin-top: 10px;
        margin-right: 100px;
        display: inline-block;
        vertical-align: top;

        .iv2-nav__item {
          font-size: 1.3rem;
          padding-right: 15px;

          .iv2-nav__link {
            &:hover {
              text-decoration: none;
            }
          }

          > .iv2-nav__list {
            .iv2-nav__item {
              font-size: 1.3rem;

              .iv2-nav__link {
                &::before {
                  content: "";
                  display: inline-block;
                  margin-right: -38px;
                  margin-left: 0;
                  margin-bottom: 10px;
                  width: 61px;
                  transform: rotate(90deg);
                  position: relative;
                  left: -25px;
                }
              }
            }
          }
        }
      }
    }

    .iv2-nav__footer {
      margin-left: 0;
      margin-right: 0;
      padding-bottom: 60px;
    }
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
  }
}

/* PC */
@media (min-width: 1200px) {
  .iv2-global {
    .iv2-global__items {
      .iv2-global__items {
        .iv2-global__menu {
          .iv2-global__btn {
            .iv2-global__btn-icon {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }

    .iv2-nav {
      .iv2-nav__block {
        .iv2-nav__list {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          max-height: 400px;
          align-content: flex-start;
          .iv2-nav__item {
            line-height: 2.5;
            width: 33.3%;
            > .iv2-nav__list {
              .iv2-nav__item {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

/* TABLET or SP */
@media (max-width: 1199px) {
  .iv2-global {
    .iv2-global__items {
      .iv2-global__logo {
        .iv2-global__link {
          margin-bottom: 0;
          height: 100%;
          display: inline-block;

          .iv2-global__logo-img {
            left: 5%;
          }
        }
      }
    }
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-global {
    .iv2-global__items {
      .iv2-global__logo {
        // height: 100px;

        &.active {
          height: 160px;
          margin: 20px;
        }
      }
      .iv2-global__menu {
        .iv2-global__sub-menu {
          .iv2-global__sub-menu-item {
            font-size: 1.5rem;
            width: 80px;

            .iv2-global__sub-menu-link {
              &:hover {
                text-decoration: none;
              }
            }

            .iv2-global__sub-menu-item-text {
              font-size: 10px;
              font-weight: 400;
              margin-top: 6px;
              line-height: 1.3;
            }
          }
        }
      }
    }

    .iv2-nav {
      padding-left: 100px;
      .iv2-nav__block {
        .iv2-nav__list {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          max-height: 400px;
          align-content: flex-start;
          .iv2-nav__item {
            line-height: 2.5;
            font-size: 1rem;
            width: 33.3%;
            > .iv2-nav__list {
              .iv2-nav__item {
                font-size: 1rem;
                width: 100%;

                .iv2-nav__link {
                  &::before {
                    width: 41px;
                    margin-right: -26px;
                    left: -18px;
                    top: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-global {
    .iv2-global__items {
      width: 100vw;

      .iv2-global__logo {
        width: 80%;
        height: 70px;
        margin: 10px;
        &.active {
          width: 80%;
          height: 50px;
        }
      }

      .iv2-global__menu {
        margin-left: auto;
        padding: 18px 16px;
        background-color: unset;

        &.inversion {
          mix-blend-mode: exclusion;

          .iv2-global__btn {
            .iv2-global__btn-icon {
              .iv2-global__top-drawer,
              .iv2-global__bottom-drawer {
                border-top: 1px solid;
                border-color: #fff;
              }
            }
          }
        }

        .iv2-global__btn {
          .iv2-global__btn-icon {
            width: 17px;
            height: 15px;
          }
        }
      }
    }

    .iv2-global__heading-bar {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      display: none;
      animation: fadeIn 0.3s linear 0s;

      &.active {
        display: block;
      }
    }

    .iv2-nav {
      padding-top: 50px;
      padding-left: 0px;
      padding-bottom: 10rem;
      padding-bottom: calc(10rem + env(safe-area-inset-bottom));

      .iv2-nav__block {
        margin-left: 0;
      }

      .iv2-nav__block {
        padding: 30px 20px 20px;

        .iv2-nav__list {
          display: block;
          margin: auto;
          padding-left: 12px;
          float: none;

          .iv2-nav__item {
            position: relative;
            display: block;
            font-size: 1em;

            .iv2-nav__link {
              display: block;
              padding: 5px 0;
            }

            > .iv2-nav__list {
              > .iv2-nav__item {
                font-size: 1em;

                .iv2-nav__link {
                  &::before {
                    margin-bottom: 5px;
                    width: 42px;
                    left: -30px;
                  }
                }
              }
            }
          }
        }
      }

      .iv2-nav__mobile-sns-item {
        font-size: 1.3em;
        display: inline-block;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }

      .iv2-nav__footer {
        padding: 1.5rem;
      }
    }
  }
}
</style>
