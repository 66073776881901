<template>
  <section class="iv2-page-header">
    <div
      v-if="items.header_type == 'add_catch'"
      class="iv2-page-header-type1 fit-img overflow-hidden"
    >
      <picture>
        <source
          type="image/webp"
          :srcset="
            items.header_image + $store.state.publicLang.general.image_webp
          "
        />
        <img
          class="iv2-page-header__img"
          :src="items.header_image"
          :alt="items.title + $store.state.publicLang.page.alt"
        />
      </picture>
      <div class="iv2-page-header__cover position-absolute"></div>
      <div class="iv2-page-header__title position-relative">
        <h1 class="iv2-page-header__main-title m-0">{{ items.title }}</h1>
        <p v-if="items.subtitle" class="iv2-page-header__sub-title m-0">
          {{ items.subtitle }}
        </p>
      </div>
      <div
        v-if="
          items.header_title ||
          items.header_subtitle ||
          items.header_summary_text
        "
        class="iv2-page-header__text position-relative"
      >
        <div class="row">
          <div v-if="items.header_title" class="col-md-4 ml-auto">
            <p
              v-html="items.header_title"
              class="iv2-page-header__main-lead"
            ></p>
            <p
              v-html="items.header_subtitle"
              class="iv2-page-header__sub-lead"
            ></p>
          </div>
          <div v-if="items.header_summary_text" class="col-md-4 mr-auto">
            <p
              v-html="items.header_summary_text"
              class="iv2-page-header__description"
            ></p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="items.header_type == 'add_bg'"
      class="iv2-page-header-type1 fit-img overflow-hidden"
    >
      <picture>
        <source
          type="image/webp"
          :srcset="
            items.header_image + $store.state.publicLang.general.image_webp
          "
        />
        <img
          class="iv2-page-header__img"
          :src="items.header_image"
          :alt="items.title + $store.state.publicLang.page.alt"
        />
      </picture>
      <div class="iv2-page-header__cover position-absolute"></div>
      <div class="iv2-page-header__title position-relative">
        <h1 class="iv2-page-header__main-title m-0">{{ items.title }}</h1>
        <p v-if="items.subtitle" class="iv2-page-header__sub-title m-0">
          {{ items.subtitle }}
        </p>
      </div>
    </div>
    <div
      v-else-if="items.header_type == 'add_cover'"
      class="iv2-page-header-type2 overflow-hidden"
    >
      <div class="iv2-page-header__title position-relative">
        <h1 class="iv2-page-header__main-title theme__page-header-title h4 m-0">
          {{ items.title }}
        </h1>
        <p
          v-if="items.subtitle"
          class="iv2-page-header__sub-title theme__page-header-sub-title m-0"
        >
          {{ items.subtitle }}
        </p>
      </div>
      <div class="iv2-page-header__img-band fit-img">
        <picture>
          <source
            type="image/webp"
            :srcset="
              items.header_image + $store.state.publicLang.general.image_webp
            "
          />
          <img
            class="iv2-page-header__img"
            :src="items.header_image"
            :alt="items.title + $store.state.publicLang.page.alt"
          />
        </picture>
        <div class="iv2-page-header__cover position-absolute"></div>
      </div>
    </div>
    <div
      v-else-if="items.header_type == 'text_only'"
      class="iv2-page-header-type3 overflow-hidden"
    >
      <div class="iv2-page-header__title position-relative">
        <h1 class="iv2-page-header__main-title theme__page-header-title h4 m-0">
          {{ items.title }}
        </h1>
        <p
          v-if="items.subtitle"
          class="iv2-page-header__sub-title theme__page-header-sub-title m-0"
        >
          {{ items.subtitle }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    items: Object,
  },
};
</script>

<style lang="scss">
.iv2-page-header {
  margin-top: 70px;

  .iv2-page-header__title {
    padding: 50px;
    color: #fff;

    .iv2-page-header__main-title {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .iv2-page-header__sub-title {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .iv2-page-header__cover {
    background-color: rgba(0, 0, 0, 0.15);
  }

  .iv2-page-header__img-band {
    .iv2-page-header__cover {
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    .iv2-page-header__img {
      width: 100%;
      max-height: 100%;
    }
  }

  /* ページヘッダー(タイプ1) */

  .iv2-page-header-type1 {
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);

    .iv2-page-header__img {
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .iv2-page-header__cover {
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    .iv2-page-header__title,
    .iv2-page-header__text {
      color: #fff;
      z-index: 3;
    }

    .iv2-page-header__text {
      .iv2-page-header__main-lead {
        line-height: 1.2;
      }
    }
  }
}

/* PC */
@media (min-width: 1200px) {
  .iv2-page-header {
    .iv2-page-header-type1 {
      padding-bottom: 150px;

      .iv2-page-header__text {
        .iv2-page-header__main-lead {
          font-size: 3rem;
        }

        .iv2-page-header__description {
          line-height: 2;
        }
      }
    }

    .iv2-page-header__img-band {
      height: 250px;
    }
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-page-header {
    .iv2-page-header__title {
      padding: 30px;
    }

    .iv2-page-header-type1 {
      padding-bottom: 100px;

      .iv2-page-header__text {
        .iv2-page-header__description {
          line-height: 2;
        }

        .iv2-page-header__main-lead {
          font-size: 2rem;
        }
      }
    }

    .iv2-page-header__img-band {
      height: 150px;
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-page-header {
    margin-top: 50px;

    .iv2-page-header__title {
      padding: 20px;
    }

    .iv2-page-header-type1 {
      padding-bottom: 50px;

      .iv2-page-header__text {
        padding: 20px;

        .iv2-page-header__description {
          font-size: 1rem;
        }

        .iv2-page-header__main-lead {
          font-size: 1.5rem;
        }
      }
    }

    .iv2-page-header__img-band {
      height: 100px;
    }
  }
}
</style>
