<template>
  <section>
    <div class="iv2-hero">
      <div
        v-if="items.main_visual_type == 'slider'"
        :class="displayType"
        class="iv2-hero-slider position-relative overflow-hidden"
      >
        <div
          v-for="media in items.main_visuals"
          :key="media.id"
          class="iv2-hero-content"
        >
          <transition name="slider-fade" appear>
            <div v-if="currentSlide == media.sort_key" class="iv2-hero-inner">
              <!-- PC画像 -->
              <picture :class="setDisplayTypeByDeviceSize(media, 'pc')">
                <source
                  type="image/webp"
                  :srcset="
                    media.file_url + $store.state.publicLang.general.image_webp
                  "
                />
                <img
                  class="iv2-hero-image"
                  :src="media.file_url"
                  :alt="
                    organization.name + $store.state.publicLang.general.hero_alt
                  "
                />
              </picture>
              <!-- PC画像 -->
              <!-- SP画像 -->
              <picture
                v-if="media.file_url_sp != null"
                :class="setDisplayTypeByDeviceSize(media, 'sp')"
              >
                <source
                  type="image/webp"
                  :srcset="
                    media.file_url_sp +
                    $store.state.publicLang.general.image_webp
                  "
                />
                <img
                  class="iv2-hero-image"
                  :src="media.file_url_sp"
                  :alt="
                    organization.name + $store.state.publicLang.general.hero_alt
                  "
                />
              </picture>
              <!-- SP画像 -->
              <div class="iv2-hero-cover position-absolute">
                <a
                  v-if="media.use_link === 1 && media.is_external === 0"
                  :href="media.link_href"
                  target="_self"
                  class="iv2-hero-link d-block w-100 h-100"
                ></a>
                <a
                  v-else-if="media.use_link === 1 && media.is_external === 1"
                  :href="media.link_href"
                  target="_blank"
                  class="iv2-hero-link d-block w-100 h-100"
                ></a>
              </div>
              <div class="iv2-hero-catchphrase position-absolute">
                <a
                  v-if="media.use_link === 1 && media.is_external === 0"
                  :href="media.link_href"
                  target="_self"
                  class="iv2-hero-link position-absolute d-block w-100 h-100"
                ></a>
                <a
                  v-else-if="media.use_link === 1 && media.is_external === 1"
                  :href="media.link_href"
                  target="_blank"
                  class="iv2-hero-link position-absolute d-block w-100 h-100"
                ></a>
                <div class="row flex-md-row-reverse">
                  <picture
                    v-if="
                      media.catchphrase_type == 'img' &&
                      media.catchphrase_image_url
                    "
                    :class="{
                      'm-auto': !media.summary_text,
                      'mr-md-auto': media.summary_text,
                    }"
                    class="iv2-hero-catchphrase__picture text-center"
                  >
                    <source
                      type="image/webp"
                      :srcset="
                        media.catchphrase_image_url +
                        $store.state.publicLang.general.image_webp
                      "
                    />
                    <img
                      :src="media.catchphrase_image_url"
                      :alt="
                        organization.name +
                        $store.state.publicLang.general.catchphrase_alt
                      "
                      class="iv2-hero-catchphrase__img"
                    />
                  </picture>
                  <div
                    class="iv2-hero-catchphrase__text"
                    :class="{
                      'mx-auto':
                        media.catchphrase_type == 'text' ||
                        (media.catchphrase_type == 'img' &&
                          !media.catchphrase_image_url),
                      'iv2-hero-catchphrase__text-sp ml-md-auto':
                        media.catchphrase_type == 'img' &&
                        media.summary_text &&
                        media.catchphrase_image_url,
                    }"
                  >
                    <h2
                      v-if="
                        media.catchphrase_type == 'text' &&
                        media.catchphrase_text
                      "
                      class="iv2-hero__main-title text-pre"
                    >
                      {{ media.catchphrase_text }}
                    </h2>
                    <h3
                      v-if="media.summary_text"
                      :class="{
                        'mr-3': media.catchphrase_type == 'text',
                        'mx-4':
                          media.catchphrase_type == 'img' &&
                          media.catchphrase_image_url,
                      }"
                      class="iv2-hero__sub-title text-pre"
                    >
                      {{ media.summary_text }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div
        v-else-if="items.main_visual_type == 'movie'"
        :class="displayType"
        class="iv2-hero-video position-relative overflow-hidden"
      >
        <!-- PC用 -->
        <video
          :class="setDisplayTypeByDeviceSize(data, 'pc')"
          class="iv2-hero-content fit-img"
          autoplay
          muted
          loop
          playsinline
        >
          <source :src="data.file_url" type="video/mp4" />
        </video>
        <!-- PC用 -->
        <!-- SP用 -->
        <video
          v-if="data.file_url_sp != null"
          :class="setDisplayTypeByDeviceSize(data, 'sp')"
          class="iv2-hero-content fit-img"
          autoplay
          muted
          loop
          playsinline
        >
          <source :src="data.file_url_sp" type="video/mp4" />
        </video>
        <!-- SP用 -->

        <div class="iv2-hero-cover position-absolute">
          <a
            v-if="data.use_button === 1 && data.is_external === 0"
            :href="data.link_href"
            target="_self"
            class="iv2-hero-link d-block w-100 h-100"
          ></a>
          <a
            v-else-if="data.use_button === 1 && data.is_external === 1"
            :href="data.link_href"
            target="_blank"
            class="iv2-hero-link d-block w-100 h-100"
          ></a>
        </div>
        <div class="iv2-hero-catchphrase position-absolute">
          <div class="row flex-md-row-reverse">
            <picture
              v-if="
                data.catchphrase_type == 'img' && data.catchphrase_image_url
              "
              :class="{
                'm-auto': !data.summary_text,
                'mr-md-auto': data.summary_text,
              }"
              class="iv2-hero-catchphrase__picture text-center"
            >
              <source
                type="image/webp"
                :srcset="
                  data.catchphrase_image_url +
                  $store.state.publicLang.general.image_webp
                "
              />
              <img
                :src="data.catchphrase_image_url"
                :alt="
                  organization.name +
                  $store.state.publicLang.general.catchphrase_alt
                "
                class="iv2-hero-catchphrase__img"
              />
            </picture>
            <div
              class="iv2-hero-catchphrase__text"
              :class="{
                'mx-auto':
                  data.catchphrase_type == 'text' ||
                  (data.catchphrase_type == 'img' &&
                    !data.catchphrase_image_url),
                'iv2-hero-catchphrase__text-sp ml-md-auto':
                  data.catchphrase_type == 'img' &&
                  data.summary_text &&
                  data.catchphrase_image_url,
              }"
            >
              <h2
                v-if="data.catchphrase_type == 'text' && data.catchphrase_text"
                class="iv2-hero__main-title text-pre"
              >
                {{ data.catchphrase_text }}
              </h2>
              <h3
                v-if="data.summary_text"
                :class="{
                  'mr-3': data.catchphrase_type == 'text',
                  'mx-4':
                    data.catchphrase_type == 'img' &&
                    data.catchphrase_image_url,
                }"
                class="iv2-hero__sub-title text-pre"
              >
                {{ data.summary_text }}
              </h3>
            </div>
          </div>
          <div v-if="data.use_button == 1" class="mt-4 text-center">
            <span>
              <a
                :href="data.link_href"
                :target="windowSelected"
                class="iv2-hero-btn theme__main-btn is-btn is-btn-ghost2 size-18"
                >{{ data.button_label }}</a
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    items: Object,
    organization: Object,
    bottom: Array,
  },

  data() {
    return {
      currentSlide: 1,
      indigateProgress: 0,
      displayType: "",
      windowSelected: "",
      data: {},
    };
  },

  created() {
    if (this.items.main_visual_type == "movie") {
      this.data = this.items.main_visuals[0];
      if (this.data.is_external == 1) {
        this.windowSelected = "_blank";
      } else {
        this.windowSelected = "_self";
      }
    }

    this.displayType = this.items.display_type;

    // ボトムメニューの有無で余白を付けるかどうかを制御する
    this.displayType += this.bottom.length > 0 ? " has-bottom-nav" : "";
  },

  mounted() {
    if (
      this.items.main_visual_type == "slider" &&
      this.items.main_visuals.length > 1
    ) {
      setInterval(() => {
        this.currentSlide =
          this.currentSlide < this.items.main_visuals.length
            ? this.currentSlide + 1
            : 1;
      }, this.items.slide_second);
    }
  },

  methods: {
    // メインビジュアルのスライドごとの表示クラス制御
    setDisplayTypeByDeviceSize(media = {}, type = "pc") {
      let ret = "";
      if (media != {} && media.file_url_sp != null) {
        switch (type) {
          // SP時
          case "sp":
            ret =
              this.items.main_visual_type == "slider"
                ? "d-sm-none d-inline"
                : "d-sm-none d-block";
            break;
          // その他(デフォルト:PC)
          default:
            ret =
              this.items.main_visual_type == "slider"
                ? "d-sm-inline d-none"
                : "d-sm-block d-none";
        }
      }
      return ret;
    },
  },
};
</script>

<style lang="scss">
.slider-fade-enter-active,
.slider-fade-leave-active {
  transition: opacity 1s;
}
.slider-fade-enter,
.slider-fade-leave-to {
  opacity: 0;
}

.iv2-hero {
  z-index: 1;

  .full_screen,
  .responsive.iv2-hero-slider {
    height: calc(100vh);
  }

  .full_screen {
    &.iv2-hero-video {
      .iv2-hero-content {
        height: 100%;
      }
    }
  }

  .iv2-hero-slider {
    .iv2-hero-content {
      position: absolute;
      width: 100%;
      height: 100%;

      .iv2-hero-inner {
        width: 100%;
        height: 100%;

        .iv2-hero-image {
          margin: 0 !important;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .iv2-hero-video {
    .iv2-hero-content {
      margin: 0 !important;
      width: 100%;
      object-fit: cover;
      display: block;
    }

    .iv2-hero-cover {
      display: none;
    }
  }

  .iv2-hero-cover {
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .iv2-hero-link {
    cursor: pointer;
    z-index: 21;
  }

  .iv2-hero-catchphrase {
    width: 90%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin: auto;
    color: #ffffff;
    z-index: 11;
    animation-name: fade;
    animation-duration: 3s;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
    padding-top: 50px;

    .iv2-hero-catchphrase__picture {
      max-width: 600px;

      .iv2-hero-catchphrase__img {
        max-height: 300px;
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
      }
    }

    .iv2-hero-catchphrase__text {
      -ms-writing-mode: tb-rl;
      -webkit-writing-mode: vertical-rl;
      writing-mode: vertical-rl;
      -webkit-text-orientation: upright;
      text-orientation: upright;

      .iv2-hero__main-title {
        font-size: 3rem;
        max-height: 65vh;
      }

      .iv2-hero__sub-title {
        font-size: 1.5rem;
        max-height: 65vh;
      }
    }
  }

  .iv2-hero-btn {
    max-width: 100%;
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

/* PC */
@media (min-width: 1200px) {
  .iv2-hero {
    .responsive {
      &.iv2-hero-video {
        height: 100vh;

        .iv2-hero-content {
          height: 100%;
        }
      }
    }
  }
}

/* TABLET or SP */
@media (max-width: 1199px) {
  .iv2-hero {
    .responsive {
      .iv2-hero-catchphrase {
        .iv2-hero-catchphrase__text {
          .iv2-hero__main-title {
            font-size: 2rem;
            line-height: 1.2;
          }

          .iv2-hero__sub-title {
            font-size: 1.2rem;
          }
        }

        .iv2-hero-btn {
          display: none;
        }
      }
    }

    .iv2-hero-catchphrase {
      .iv2-hero-catchphrase__text {
        .iv2-hero__main-title {
          font-size: 2rem;
          line-height: 1.2;
        }

        .iv2-hero__sub-title {
          font-size: 1.2rem;
        }
      }
    }
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-hero {
    .responsive {
      &.iv2-hero-slider {
        height: 50vw;
      }

      .iv2-hero-catchphrase {
        padding-top: 60px;

        .iv2-hero-catchphrase__picture {
          .iv2-hero-catchphrase__img {
            max-height: 250px;
          }
        }

        .iv2-hero-catchphrase__text {
          .iv2-hero__main-title,
          .iv2-hero__sub-title {
            max-height: 40vh;
          }
        }
      }
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-hero {
    .full_screen {
      height: calc(100vh);

      .iv2-hero-catchphrase {
        padding-top: 0px;

        .iv2-hero-catchphrase__picture {
          width: 90%;
          margin: auto;

          .iv2-hero-catchphrase__img {
            max-height: 250px;
          }
        }

        .iv2-hero-catchphrase__text {
          &.iv2-hero-catchphrase__text-sp {
            -ms-writing-mode: unset;
            writing-mode: unset;
            text-orientation: unset;
            margin: auto;
          }
        }
      }

      &.has-bottom-nav {
        height: calc(100vh - calc(60px + env(safe-area-inset-bottom)));
      }
    }

    .responsive {
      &.iv2-hero-slider {
        height: 50vw;
      }

      &.iv2-hero-video {
        .iv2-hero-cover {
          display: block;
        }
      }

      .iv2-hero-catchphrase {
        .iv2-hero-catchphrase__picture {
          width: 80%;
          margin: auto;

          .iv2-hero-catchphrase__img {
            max-height: 150px;
          }
        }

        .iv2-hero-catchphrase__text {
          display: none;
        }
      }
    }
  }
}
</style>
