<template>
  <div
    class="iv2-theme"
    id="app"
    :class="'iv2-font__' + theme.font_type"
    :style="colorScheme()"
  >
    <div v-if="openType === 1 && statusCode === 200">
      <div class="iv2-global__top-content position-fixed">
        <TheGlobal
          :global="global"
          :sns="sns"
          :organization="organization"
          :information="information"
          :rss="rss"
          :count="count.global"
        />
        <TheExtra :extra="extra" />
      </div>
      <TheExtraSns :sns="sns" :rss="rss" />
      <transition mode="out-in" :name="pageAnimation" appear>
        <router-view
          :animation="pageAnimation"
          :organization="organization"
          :rss="rss"
          :bottom="bottom"
        />
      </transition>

      <TheBanner :banner="banner" />

      <TheContentFooter
        v-if="theme.is_use_content_footer === 1"
        :theme="theme"
      />

      <TheFooter
        :class="{ 'mb-0': bottom.length === 0 }"
        :footer="footer"
        :sns="sns"
        :organization="organization"
        :information="information"
        :rss="rss"
        :translation="translation"
        :count="count.footer"
        :theme="theme"
      />

      <!-- タブレット、スマホのみ表示 -->
      <TheBottom v-if="bottom.length > 0" :bottom="bottom" />

      <BaseColor v-if="colorDisplay" @change="colorChange" />
    </div>

    <!-- サイト非公開 -->
    <div v-else-if="openType === 0 && statusCode === 200">
      <Closed />
    </div>

    <!-- 500エラー -->
    <div v-else-if="statusCode === 500">
      <Error500 />
    </div>

    <!-- 503エラー -->
    <div v-else-if="statusCode === 503">
      <Error503 />
    </div>

    <!-- プレビュー -->
    <div v-if="preview">
      <BasePreview />
    </div>

    <!-- モーダル用オーバレイ -->
    <div class="iv2-modal-overlay hide" onclick="downModal()"></div>

    <!-- one signalアイコンにカラーチェンジャー反映 -->
    <div v-if="theme.color_scheme" is="style">
      #onesignal-bell-container.onesignal-reset
      .onesignal-bell-launcher.onesignal-bell-launcher-theme-default
      .onesignal-bell-launcher-button svg .background {fill:
      {{ theme.color_scheme.button_bg }} !important; }
      #onesignal-bell-container.onesignal-reset
      .onesignal-bell-launcher.onesignal-bell-launcher-theme-default
      .onesignal-bell-launcher-button svg .foreground {fill:
      {{ theme.color_scheme.button_text }} !important; }
      #onesignal-bell-container.onesignal-reset
      .onesignal-bell-launcher.onesignal-bell-launcher-theme-default
      .onesignal-bell-launcher-button svg .stroke {stroke:
      {{ theme.color_scheme.button_text }} !important; }
    </div>
    <!-- one signalアイコンにカラーチェンジャー反映 -->
  </div>
</template>

<script>
import Error500 from "./components/systems/Error500.vue";
import Error503 from "./components/systems/Error503.vue";
import Closed from "./components/systems/Closed.vue";
import TheGlobal from "./components/global/TheGlobal.vue";
import TheExtra from "./components/global/TheExtra.vue";
import TheExtraSns from "./components/global/TheExtraSns.vue";
import TheBanner from "./components/global/TheBanner.vue";
import TheFooter from "./components/global/TheFooter.vue";
import TheContentFooter from "./components/global/TheContentFooter.vue";
import TheBottom from "./components/global/TheBottom.vue";
import BasePreview from "@/components/items/BasePreview.vue";
import InitialSetting from "@/mixins/InitialSetting";
import AddRouter from "@/mixins/AddRouter";
import BaseColor from "@/components/items/BaseColor.vue";

export default {
  components: {
    Error500,
    Error503,
    Closed,
    TheGlobal,
    TheExtra,
    TheExtraSns,
    TheBanner,
    TheFooter,
    TheContentFooter,
    TheBottom,
    BasePreview,
    BaseColor,
  },

  mixins: [InitialSetting, AddRouter],

  data() {
    return {
      pageAnimation: "fade",
      colorDisplay: false,
      userAgent: "",
    };
  },

  created() {
    // QRコードからのアクセス
    const siteId = document.head.querySelector("[name=sid][content]").content;
    const id = siteId;
    const key = "qrcode";
    if (process.env.NODE_ENV === "production") {
      this.environment = "production";
    } else {
      this.environment = "dev";
    }
    window.ivGetParams(key)
      ? window.ivSendActivities(
          id,
          {
            user_agent: navigator.userAgent.toLowerCase(),
            provide_name: key,
            provide_id: window.ivGetParams(key),
          },
          this.environment
        )
      : "";

    // safariのみmp4ファイルをキャッシュから取り除く
    this.userAgent = navigator.userAgent;
    setInterval(() => {
      if (
        this.userAgent.indexOf("Safari") != -1 &&
        this.userAgent.indexOf("Chrome") == -1
      ) {
        navigator.serviceWorker.controller.postMessage("updateCache");
        window.location.reload(true);
      }
    }, 60 * 60 * 1000);

    // カラーチェンジャーの表示判定
    const display = document.head.querySelector("[name=color_changer]");
    if (display && display.content == "true") {
      this.colorDisplay = true;
    }
  },

  methods: {
    // カラースキームを定義
    colorScheme() {
      if (this.theme.color_scheme) {
        return {
          "--main-color": this.theme.color_scheme.main_color,
          "--sub-color": this.theme.color_scheme.sub_color,
          "--main-bg": this.theme.color_scheme.main_bg,
          "--sub-bg": this.theme.color_scheme.sub_bg,
          "--main-text": this.theme.color_scheme.main_text,
          "--sub-text": this.theme.color_scheme.sub_text,
          "--heading-text": this.theme.color_scheme.heading_text,
          "--global-bg": this.theme.color_scheme.global_bg,
          "--global-text": this.theme.color_scheme.global_text,
          "--nav-bg": this.theme.color_scheme.nav_bg,
          "--nav-text": this.theme.color_scheme.nav_text,
          "--footer-bg": this.theme.color_scheme.footer_bg,
          "--footer-text": this.theme.color_scheme.footer_text,
          "--bottom-bg": this.theme.color_scheme.bottom_bg,
          "--bottom-text": this.theme.color_scheme.bottom_text,
          "--page-title": this.theme.color_scheme.page_title,
          "--button-bg": this.theme.color_scheme.button_bg,
          "--button-text": this.theme.color_scheme.button_text,
        };
      }
    },

    // デモ用カラーチェンジャー
    colorChange(obj) {
      this.theme.color_scheme = obj;
    },
  },
};
</script>

<style lang="scss"></style>
